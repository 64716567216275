<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <adjust
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalAdjustActive"
      :show.sync="show"
      :data="modalAdjustData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('Start Date')"
                label-for="start-date"
                class="mb-75 mr-75"
              >
                <flat-pickr
                  id="startDate"
                  v-model="startDate"
                  class="form-control"
                  :config="configs"
                  @input="onChangeDate()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('End Date')"
                label-for="end-date"
                class="mb-75 mr-75"
              >
                <flat-pickr
                  id="endDate"
                  v-model="endDate"
                  class="form-control"
                  :config="configs"
                  @input="onChangeDate()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Search')"
                label-for="search"
                class="mb-75 mr-75"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    id="search"
                    v-model="searchQuery"
                    class="w-full d-inline-block mr-1"
                    block
                    :placeholder="$t('Search') + '...'"
                    @input="typing($event)"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    class="mr-1"

                    size="sm"
                    @click="importData()"
                  >
                    <span class=" ">{{ $t("Import") }}</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class=""

                    @click="viewProduct('add')"
                  >
                    <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                  </b-button></div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row cols="4">
                <b-col>
                  <div>
                    <label>{{ $t("Show") }}</label>
                    <label
                      class="text-muted ml-2 mr-2"
                    >{{ currentPage * perPage - (perPage - 1) }} -
                      {{
                        queriedItems - currentPage * perPage > 0
                          ? currentPage * perPage
                          : queriedItems
                      }}
                      {{ $t("of") }} {{ queriedItems }}</label>
                    <label for="">{{ $t("entries") }}</label>

                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="select-size-sm"
                      @input="pageSize"
                    /></div></b-col>
                <b-col>
                  <div>
                    <label>{{ $t("Warehouse") }}</label>
                    <v-select
                      v-model="warehouse"
                      multiple
                      size="sm"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="respWarehouseData"
                      :reduce="(respWarehouseData) => respWarehouseData._id"
                      :clearable="true"
                      class="select-size-sm"
                      @input="onSearch"
                    />
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <label>{{ $t("Category") }}</label>
                    <v-select
                      v-model="categories"
                      multiple
                      size="sm"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="respDataCategory"
                      :reduce="(respDataCategory) => respDataCategory._id"
                      :clearable="true"
                      class="select-size-sm"
                      @input="onSearch"
                    />
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <label>{{ $t("Brand") }}</label>
                    <v-select
                      v-model="brands"
                      multiple
                      size="sm"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="respDataBrand"
                      :reduce="(respDataBrand) => respDataBrand._id"
                      :clearable="true"
                      class="select-size-sm"
                      @input="onSearch"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
   
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <b-form-group
                :label="`${$t('Update product')}:`"
                label-cols-sm="2"
                class="mt-1"
              >
                <div class="d-flex">
                  <b-form-file
                    id="file-default"
                    ref="refInputCSV"
                    class="d-flex mr-1"
                    accept=".csv"
                    :disabled="disableCSV"
                    @change="inputCSVRenderer"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="d-flex"
                    :disabled="disableCSV"
                    @click="saveCSV()"
                  >
                    <feather-icon icon="SaveIcon" />
                    <!-- <span class="align-middle">Add Users</span> -->
                  </b-button>
                </div>
                <div class="mt-1">
                  <label>{{ $t("Product") }} {{ valueProduct }} /
                    {{ maxProduct }} ({{ nameProduct }})</label>
                  <b-progress :max="maxProduct">
                    <b-progress-bar :value="valueProduct">
                      <strong>{{ valueProduct }} / {{ maxProduct }}</strong>
                    </b-progress-bar>
                  </b-progress>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <b-form-group
                :label="`${$t('Export')}:`"
                label-cols-sm="2"
                class="mt-1"
              >
                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="d-flex"
                    @click="exportCSV()"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-1"
                    />
                    <span class="align-middle">{{ $t('Export') }}</span>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-start">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  class="d-flex mr-2"
                  @click="deleteSelectedData()"
                >
                  <span
                    class="align-middle"
                  >{{ $t("Delete selected data") }} ({{
                    selected.length
                  }})</span>
                </b-button>

                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <template #head(check)="data">
            <div class="d-flex align-items-center justify-content-between">
              <b-form-checkbox
                :id="`all`"
                :name="`all`"
                :checked="checkAll()"
                @change="selectAll($event)"
              />
              <span>{{ $t("Select all") }}</span>
            </div>
          </template>
          <template #cell(check)="data">
            <b-form-checkbox
              :id="`checkbox-${data.index}`"
              :name="`checkbox-${data.index}`"
              :checked="checkProduct(data.item._id)"
              @change="selectProduct(data.item._id)"
            />
          </template>
          <!-- Column: Images -->
          <template #cell(images)="data">
            <b-row>
              <b-img
                v-if="data.item.images.length > 0"
                :src="data.item.images[0]"
                fluid
                thumbnail
                width="100px"
                :alt="'Product_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Product_' + data.item._id"
              />
            </b-row>
          </template>
          <template #cell(sequence)="data">
            <p>{{ data.index + 1 }}</p>
          </template>
          <template #cell(name)="data">
            <p>{{ showFromCurrentLanguage(data.item.name) }}</p>
          </template>
          <template #cell(productCategoryId)="data">
            <b-row>
              <p class="align-text-bottom line-height-1">
                {{
                  data.item.productCategoryId
                    ? showFromCurrentLanguage(data.item.productCategoryId.name)
                    : ""
                }}
              </p>
            </b-row>
          </template>
          <template #cell(productBrandId)="data">
            <b-row>
              <p class="align-text-bottom line-height-1">
                {{
                  data.item.productBrandId
                    ? showFromCurrentLanguage(data.item.productBrandId.name)
                    : ""
                }}
              </p>
            </b-row>
          </template>
          <template #cell(created)="data">
            <p>{{ data.item.created | formatDateTime }}</p>
          </template>
          <template #cell(updated)="data">
            <p>{{ data.item.updated | formatDateTime }}</p>
          </template>
          <template #cell(expirationDate)="data">
            <p v-if="data.item.expirationDate">
              {{ data.item.expirationDate | formatDateTime }}
            </p>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="transferData(data.item)">
                <span class="align-middle ml-50">{{
                  $t("Transfer goods")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="modalAdjust(data.item)">
                <span class="align-middle ml-50">{{
                  $t("Adjust quantity")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewProduct(data.item._id)">
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormFile,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/warehouseProduct'
import storeModuleCategory from '@/store/services/eCommerce/productCategory'
import storeModuleBrand from '@/store/services/eCommerce/productBrand'

import debounce from 'debounce'
import Papa from 'papaparse'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { Thai } from 'flatpickr/dist/l10n/th.js'
import Adjust from './Adjust/Adjust.vue'

const STORE_MODULE_NAME = 'warehouseProduct'
const STORE_MODULE_NAME_CATEGORY = 'productCategory'
const STORE_MODULE_NAME_BRAND = 'productBrand'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BFormGroup,
    BFormFile,
    BProgress,
    BProgressBar,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Adjust,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      show: false,
      warehouse: '',
      categories: '',
      brands: '',
      isModalAdjustActive: false,
      modalAdjustData: {},
      selected: [],
      nameProduct: '',
      valueProduct: 0,
      maxProduct: 0,
      disableCSV: false,
      startDate: null,
      endDate: null,
      configs: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        locale: Thai,
      },
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        // { key: 'sequence', label: this.$t('Sequence'), sortable: false },
        { key: 'check', label: this.$t('Check'), sortable: false },
        { key: 'images', label: this.$t('Image'), sortable: false },

        {
          key: 'barCode',
          label: `${this.$t('Barcode')} (CF_BARCODE)`,
          sortable: false,
        },
        // {
        //   key: 'code',
        //   label: `${this.$t('Code')} (CF_ITEMID)`,
        //   sortable: false,
        // },
        {
          key: 'name',
          label: `${this.$t('Name')} (CF_ITEMNAME)`,
          sortable: false,
        },
        {
          key: 'productCategoryId',
          label: this.$t('Category'),
          sortable: false,
        },
        {
          key: 'productBrandId',
          label: this.$t('Brand'),
          sortable: false,
        },
        {
          key: 'sellingPrice',
          label: `${this.$t('Price')} (CF_FMLPRICE)`,
          sortable: false,
        },
        { key: 'stock', label: this.$t('Stock'), sortable: false },
        { key: 'updated', label: this.$t('Updated'), sortable: false },
        { key: 'expirationDate', label: this.$t('Expiration Date'), sortable: false },
        // { key: 'created', label: this.$t('Created'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    respWarehouseData() {
      return store.state[STORE_MODULE_NAME].respDataWarehouse != null
        ? store.state[STORE_MODULE_NAME].respDataWarehouse.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)} (${this.$t(
            e.type,
          )})`,
        }))
        : []
    },
    respDataCategory() {
      return store.state[STORE_MODULE_NAME_CATEGORY].respData != null
        ? store.state[STORE_MODULE_NAME_CATEGORY].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    respDataBrand() {
      return store.state[STORE_MODULE_NAME_BRAND].respData != null
        ? store.state[STORE_MODULE_NAME_BRAND].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.registerModule(STORE_MODULE_NAME_CATEGORY, storeModuleCategory)
    if (!store.hasModule(STORE_MODULE_NAME_BRAND)) store.registerModule(STORE_MODULE_NAME_BRAND, storeModuleBrand)
    // if(this.$router){}

    if (this.$route.query.warehouse) {
      this.warehouse = this.$route.query.warehouse
    }

    this.get()
    this.getCategory()
    this.getBrand()
    this.getWarehouse()
    this.typing = debounce(this.typing, 500)

    const userData = JSON.parse(localStorage.getItem('userData'))
    const applicationId = localStorage.getItem('applicationId')
    // WebSocket Connect
    const pathname = `/Products?type=${userData.userType}&userId=${userData.userId}&applicationId=${applicationId}`
    const params = {
      pathname,
      page: STORE_MODULE_NAME,
    }
    store.dispatch('websockets/connect', params)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.unregisterModule(STORE_MODULE_NAME_CATEGORY)
    if (store.hasModule(STORE_MODULE_NAME_BRAND)) store.unregisterModule(STORE_MODULE_NAME_BRAND)
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_CATEGORY, STORE_MODULE_NAME_BRAND }
  },
  beforeMount() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)

        if (SocketMessage.data.send) {
          if (
            SocketMessage.data.send.data.message
            === 'UPDATE_PRODUCT_WAREHOUSE_STOCK'
          ) {
            this.disableCSV = true
            this.nameProduct = SocketMessage.data.send.data.data.nameProduct
            this.valueProduct = SocketMessage.data.send.data.data.currentProduct
            this.maxProduct = SocketMessage.data.send.data.data.maxProduct
            if (this.valueProduct === this.maxProduct) {
              this.disableCSV = false
            }
          }
        }
      }
    }
  },
  methods: {
    onChangeDate() {
      console.log(this.startDate)
      console.log(this.endDate)
      this.get()
    },
    fileCSV(items) {
      const jsonData = items.map(e => ({
        CF_BARCODE: e.barCode,
        CF_PICTURE: '',
        CF_ITEMNAME: e.name.length > 0 ? e.name[0].value : '',
        CF_ITEMID: e.code,
        CF_FMLPRICE: e.sellingPrice,
        // eslint-disable-next-line no-nested-ternary
        CF_ITEMGROUPL1_GROUPNAME: e.productCategoryId ? e.productCategoryId !== null ? e.productCategoryId.name.length > 0 ? e.productCategoryId.name[0].value : '' : '' : '',
        // eslint-disable-next-line no-nested-ternary
        CF_ITEMGROUPL2_GROUPNAME: e.productBrandId ? e.productBrandId !== null ? e.productBrandId.name.length > 0 ? e.productBrandId.name[0].value : '' : '' : '',
      }))
      const csv = Papa.unparse(jsonData)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', `${new Date().getTime()}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportCSV() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/getCSV`, {})
        // eslint-disable-next-line no-unused-vars
        .then(async result => {
          this.show = false
          // console.log(result.data.data)
          const dataJson = result.data.data
          await this.fileCSV(dataJson)

          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveCSV() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save csv')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // this.show = true;
            store
              .dispatch(`${STORE_MODULE_NAME}/updateCSVStock`, {
                lists: this.lists,
              })
              .then(result => {
                // this.show = false;
              })
              .catch(error => {
                // this.show = false;
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    inputCSVRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        // ตรวจสอบขนาดไฟล์
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} CSV. ${this.$t(
                'Max size of',
              )} 700KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          const csvData = e.target.result

          // ใช้ PapaParse เพื่อแปลง CSV เป็น JSON
          Papa.parse(csvData, {
            header: true, // ใช้แถวแรกเป็นคีย์ของอ็อบเจกต์ JSON
            dynamicTyping: false, // แปลงค่าข้อมูลให้เป็นประเภทข้อมูลที่เหมาะสม (เช่น ตัวเลข, บูลีน)
            skipEmptyLines: true, // ข้ามแถวที่ว่างเปล่า
            complete: results => {
              // console.log("Parsed CSV to JSON:", results.data);
              const { data } = results
              // eslint-disable-next-line no-plusplus
              this.lists = data
              // คุณสามารถทำอะไรก็ได้กับข้อมูล JSON ที่ได้ที่นี่
              // เช่น ส่งไปยัง API หรือเก็บไว้ใน state
            },
            error: error => {
              console.error('Error parsing CSV:', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t('Failed to parse CSV file'),
                },
              })
            },
          })
        }

        reader.readAsText(input.target.files[0])
      }
    },
    deleteSelectedData() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete all selected')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/deleteSelectedData`, {
                ids: this.selected,
              })
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
                this.get()
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    selectAll(status) {
      if (status === true) {
        this.selected = this.respData.map(e => e._id)
      } else {
        this.selected = []
      }
    },
    checkAll() {
      return (
        this.selected.length === this.respData.map(e => e._id).length
        && new Set(this.selected).size
          === new Set(this.respData.map(e => e._id)).size
      )
    },
    selectProduct(id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter(e => e !== id)
      } else {
        this.selected.push(id)
      }
    },
    checkProduct(id) {
      if (this.selected.includes(id)) {
        return true
      }
      return false
    },
    importData() {
      this.$router.push({
        name: 'e-commerce-product-import-warehouse',
      })
    },
    transferData(item) {
      this.$router.push({
        name: 'e-commerce-product-transfer-view-warehouse',
        params: { id: item._id },
      })
    },
    modalAdjust(item) {
      this.modalAdjustData = item
      this.isModalAdjustActive = true
    },
    viewProduct(item) {
      this.$router.push({
        name: 'e-commerce-product-view-warehouse',
        params: { id: item },
      })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      this.get()
    },
    deleteData(id) {
      console.log(id)
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, { id })
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.currentPage = 1
      this.perPage = size
      this.get()
    },
    nextPage(page) {
      this.currentPage = page
      this.get()
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        warehouse: this.warehouse,
        categories: this.categories,
        brands: this.brands,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getCategory() {
      const obj = {
        currentPage: 1,
        pageSize: 0,
        searchQuery: '',
      }
      store
        .dispatch(`${STORE_MODULE_NAME_CATEGORY}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getBrand() {
      const obj = {
        currentPage: 1,
        pageSize: 0,
        searchQuery: '',
      }
      store
        .dispatch(`${STORE_MODULE_NAME_BRAND}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getWarehouse() {
      store
        .dispatch(`${STORE_MODULE_NAME}/getWarehouse`, {})
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    onSearch() {
      this.get()
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
